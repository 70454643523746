import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
function GalleryRibbon() {
  return (
    <div className="items-center justify-between hidden mx-4 bg-blue-600 rounded-md lg:flex h-14">
      <div className="flex text-lg text-blue-100 space-x-11">
        <div className="pl-4 ">
          <Link to={`/modes`} className="flex items-center space-x-3 ">
            <StaticImage
              src={"../images/icon-extension-white.png"}
              alt={"all modes"}
            />
            <span>All Modes</span>
          </Link>
        </div>
        <div className="flex items-center space-x-3">
          <Link
            to={`/modes/behind-the-scene`}
            className="flex items-center space-x-3 "
          >
            <StaticImage
              src={"../images/icon-question.png"}
              alt={"how modes work"}
            />
            <span>How Modes Work</span>
          </Link>
        </div>
        <div className="flex items-center space-x-3">
          <Link
            to={`/modes/submit-form`}
            className="flex items-center space-x-3 "
          >
            <StaticImage
              src={"../images/icon-plus.png"}
              alt={"submit your own mode"}
            />
            <span>Submit Your Mode to OHIF</span>
          </Link>
        </div>
        <div className="flex items-center space-x-3">
          <Link
            to={`/modes/installation`}
            className="flex items-center space-x-3 "
          >
            <StaticImage
              src="../images/install@2x.png"
              alt={"submit your own mode"}
              width={22}
              height={22}
            />
            <span>Mode Installation</span>
          </Link>
        </div>
      </div>
      <div className="text-white"></div>
    </div>
  );
}

export default GalleryRibbon;
