import React from "react";
import GalleryRibbon from "./GalleryRibbon";
import { StaticImage } from "gatsby-plugin-image";

function OHIFGalleryHeader() {
  return (
    <div>
      <div className="mx-2 xs:mx-6 flex items-center">
        <div className="w-16 xs:w-24 rounded-md mb-4">
          <StaticImage
            alt="mode gallery"
            src={"../images/icon-ohif-modes@3x.png"}
          />
        </div>
        <div className="pl-5 flex flex-col items-start">
          <h1 className="text-2xl xs:text-5xl font-light text-blue-100 ">
            OHIF Modes
          </h1>
          <p className="text-sm xs:text-md text-white pl-1 mt-1">
            Gallery of the Workflow Modes that are installable in OHIF
          </p>
        </div>
      </div>
      <div className="mt-4">
        <GalleryRibbon />
      </div>
    </div>
  );
}

export default OHIFGalleryHeader;
