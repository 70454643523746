import React from "react";
import { graphql, Link } from "gatsby";

import Page from "./../components/Page";
import ModeCard from "./../components/ModeCard";
import OHIFGalleryHeader from "./../components/OHIFGalleryHeader";
import SEO from "./../components/SEO";
import Section from "../components/Section";

function GetStartedPage({ data }) {
  const modes = data.allModesJson.nodes;

  // sort based on the id {sortId}
  const sortedModes = modes.sort((a, b) => {
    if (a.sortId < b.sortId) {
      return -1;
    }
    if (a.sortId > b.sortId) {
      return 1;
    }
    return 0;
  });

  return (
    <Page>
      <SEO title="Modes | OHIF" />
      <Section className="bg-blue-800">
        <div className="lg:grid lg:grid-cols-12">
          {/* INTRO */}
          <div className="md:main-padding-md lg:main-padding-lg xl:col-span-12 lg:col-span-12">
            <OHIFGalleryHeader />
            <div>
              <div className="grid mt-7 md:grid-cols-2 mobile-modes">
                {sortedModes.map((mode) => {
                  const { name, id } = mode;
                  return (
                    <Link key={id} to={`/modes/${id}`}>
                      <ModeCard {...mode} />
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Page>
  );
}

export const query = graphql`
  {
    allModesJson {
      nodes {
        name
        id
        title
        thumbnailAuthor
        author
        sortId
        shortDescription
        repository
        thumbnailImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default GetStartedPage;
