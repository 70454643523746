import React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

function ModeCard({
  id,
  title,
  shortDescription,
  thumbnailAuthor,
  thumbnailImage
}) {
  const image = getImage(thumbnailImage);
  return (
    <div className="h-52 mx-4 mb-4 bg-blue-700 rounded-md md:mb-8 md:h-52 card-max-width mobile-modes">
      <div className="px-4 py-4 lg:h-full">
        <div className="relative flex lg:h-full mobile-modes">
          <div className="absolute top-0 right-0 w-6">
            <StaticImage
              alt="mode gallery"
              src={"../images/icon-extension@3x.png"}
            />
          </div>
          <div className="w-24 h-24 lg:w-40 lg:h-40 lg:flex-shrink-0 lg:self-center">
            <GatsbyImage
              className="object-cover w-full h-full rounded-md"
              image={image}
              alt={title}
            />
          </div>
          <div className="flex flex-col pl-6 mb-2 lg:pt-2 mobile-mode-text">
            <div className="font-light text-white">{thumbnailAuthor}</div>
            <div className="text-2xl font-normal text-blue-100">{title}</div>
            <div className="hidden lg:block text-white lg:mt-4">
              <div className="line-clamp-3">{shortDescription}</div>
            </div>
          </div>
        </div>
        <div className="block mt-4 text-white lg:hidden line-clamp-3 lg:line-clamp-none">
          {shortDescription}
        </div>
      </div>
    </div>
  );
}

export default ModeCard;
